import es_datatables from './datatables_es.json' assert { type: 'JSON' };

const options = {
	"ordering": true,
	"bLengthChange": false,
	"searching": true,
	"info": true,
	"paging": true,
	"lengthMenu": [ [10, 25, 50, 100, -1], [10, 25, 50, 100, "All"] ],
	"pageLength": 15,
	"destroy": false,
	"async": false,
	"select": {
		// "style": 'multi',
		"info": false,
	},
	"processing": false,
	"language": es_datatables,
};


export class DatatablesProvider {
	constructor (table, moduleOptions) {
		this.table = table
		this.moduleOptions = moduleOptions
	}

	dispatch () {
		// Se asigna el object Datatables a la tabla HTML
		const datatableObject = $('#' + this.table).DataTable(Object.assign(options, this.moduleOptions));

		// Se agrega n ID a la barra de búsqueda dentro de la tabla
		$('#' + this.table + '_filter label input').attr('id', this.table + '_search_bar');

		return datatableObject;
	}

	updateOptions(newOptions) {
		this.moduleOptions = Object.assign(this.moduleOptions, newOptions);
		return this.dispatch();
	}
}
