
const alphanumericValidator = {
	validate: function(value) {
		const alphanumericRegex = /^[a-zA-Z0-9]+$/;
		return alphanumericRegex.test(value);
	},
	message: 'Solo debe contener letras y números',
};

const dateValidator = {
	validate: function(value) {
		if (!value) {
			return false; // Reject null or empty value
		}

		const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

		if (!dateRegex.test(value)) {
			return false; // Reject if the format is not YYYY-MM-DD
		}

		const parts = value.split('-');
		const year = parseInt(parts[0], 10);
		const month = parseInt(parts[1], 10) - 1; // Month is zero-based in JavaScript Date
		const day = parseInt(parts[2], 10);
		const date = new Date(year, month, day);

		// Validate the parsed date
		return (
			date.getFullYear() === year &&
			date.getMonth() === month &&
			date.getDate() === day
		);
	},

	message: 'Debe ser una fecha válida en formato DD/MM/YYYY',
};

const selectValidator = {
	validate: function(value) {
	  // Check if the value is not empty and not a default or placeholder option
	  return value !== '' && value !== 'default' && value !== 'placeholder';
	},
	message: 'Debe seleccionar una opción válida',
  };


const acquiredIDValidator = {
	validate: function(value) {
		if (!value || value === '' || value === 0) {
			return false;
		  }
		  return true;

	},
	message: 'Debe seleccionar una opción válida.'
}

const moneyValidator = {
	validate: function(value) {
		if (value === null || value.trim() === '') {
			return false;
		}

		const valueTrimmed = value.trim();

		// Expresión regular que valida número con decimales.
		const regex = /^\d*(\.\d{1,3})?$/;

		// Valida el formato y que el número no sea cero
		return regex.test(valueTrimmed) && parseFloat(valueTrimmed) !== 0;
	},

	message: 'Ingrese un valor monetario válido.'
};

const descriptionValidator = {
	validate: function(value) {
		// Check if value is not null and is a string
		return value !== null && value.trim() !== '' && typeof value === 'string';
	},

	message: 'Ingrese una descripción válida.'
};

const numberValidator = {
  validate: function(value) {
	const valueTrimmed = value.trim();

	// Regular expression to validate numbers with optional decimal places
	const regex = /^\d+(\.\d+)?$/;

	// Validate the format
	return regex.test(valueTrimmed);
  },

  message: 'Ingrese un número válido.'
};

// Export all objects as an object literal
export default {
	alphanumericValidator,
	dateValidator,
	selectValidator,
	acquiredIDValidator,
	moneyValidator,
	descriptionValidator,
	numberValidator,
};
