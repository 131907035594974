// Exportar función URLService
export function URLService(field, method, empid = 0) {
    // Obtener la ruta del elemento con id especificado por el argumento field
    let route = document.getElementById(field).getAttribute('data-route')
    // Crear un objeto URL con la ruta obtenida
    var url = new URL(route)

    // Utilizar una estructura switch para manejar diferentes valores del argumento method
    switch (method) {
        // Si method es 'GET'
        case 'GET':
            // Reemplazar el primer '0' en la ruta con el valor de empid
            url.pathname = url.pathname.replace('0', empid);
            break;
        // Si method es 'POST'
        case 'POST':
            // Si empid no es igual a 0
            if (empid != 0) {
                // Reemplazar el primer '0' en la ruta con el valor de empid
                url.pathname = url.pathname.replace('0', empid);
            } else {
                // Eliminar '/0' de la ruta
                url.pathname = url.pathname.replace('/0', '');
            }
			break;
        // Si method es 'DELETE'
        case 'DELETE':
            // Reemplazar '0/edit' en la ruta con el valor de empid
            url.pathname = url.pathname.replace('0/edit', empid);
			break;
        default:
            break;
    }

    // Devolver la URL como una cadena de texto
    return url.toString();
}

// This function takes a modal ID, a modal title and a boolean value as parameters
export function ModalService(modalID, modalTitle = '', show = false) {
	// Select the modal element by its ID and set its options
	$('#' + modalID).modal({ backdrop: 'static', keyboard: false })
	// Set the text of the modal title element to the given title or an empty string
	$('h5[class=modal-title]').text(modalTitle);

	// Show or hide the modal based on the boolean value
	$('#' + modalID).modal(show ? 'show' : 'hide');
}

class RequestError extends Error {
    constructor(message, status, errors) {
        super(message);
        this.status = status;
        this.errors = errors;
    }
}

export class RequestService {

	constructor(baseUrl) {
		this.BASE_URL = baseUrl;

		const meta = document.querySelector('meta[name="csrf-token"]');
		if (!meta) throw new Error('CSRF token meta tag no encontrado.');

		this.CSRF_TOKEN  = meta.getAttribute('content');
		if (!this.CSRF_TOKEN) throw new Error('CSRF token no encontrado.');
	}

	async get(params = {}) {
		let url = new URL(this.BASE_URL);
		Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

		const headers = new Headers({ 'Accept': 'application/json;charset=utf-8' });
		const request = new Request(url, { headers });

		try {
			const response = await fetch(request);

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const data = await response.json();

			if (data !== false) {
				data.success = true;
				return data;
			} else {
				return { success: false };
			}
		} catch (error) {
			console.error('Ha ocurrido un error:', error.message);
			return { success: false, error: error.message };
		}
	}

	async sendData(method, formData = {}) {
		if (method === 'PUT') {
			formData.append('_method', 'PUT');
		}
		const headers = new Headers({ 'X-CSRF-Token': this.CSRF_TOKEN, 'Accept': 'application/json;charset=utf-8', });
		const request = new Request(this.BASE_URL, { method: method === 'PUT' ? 'POST' : method, headers, body: formData });

		try {
			const response = await fetch(request);
			const result = await response.json();

			if (!response.ok) throw new RequestError("Ha ocurrido un error: ", response.status, result.errors)

			this.successMessage(result.success);
		} catch (error) {
			this.handleErrorResponse(error);
		}
	}

	async post(formData = {}) {
		this.sendData('POST', formData);
	}

	async put(formData = {}) {
		this.sendData('PUT', formData);
	}

	delete(title) {
		$.confirm({
			title: title,
			content: '¿Está seguro que desea eliminar?',
			type: 'red',
			typeAnimated: false,
			buttons: {
				tryAgain: {
					text: 'Ok',
					btnClass: 'btn-red',
					action: () => this.#deleteCall()
				},
				close: function () { return; }
			}
		});
	}

	handleErrorResponse = (error) => {
		let error_messages
		if (error instanceof RequestError && (error.status >= 422 && error.status <= 451)) {
			error_messages = Object.values(error.errors).reduce((acc, val) => acc.concat(val), []);
			let error_html = error_messages.map(message => `<span style="color: red;">${message}</span><br>`).join('');

			this.errorMessage('Error', error_html);
		}

		console.error(error.message, error.stack, error_messages);
	}

	errorMessage = (title, message) => {
		$.confirm({
			title: title,
			columnClass: 'col-md-4 col-md-offset-8 col-xs-4 col-xs-offset-8',
    		containerFluid: true,
			type: 'red',
			content: message,
			autoClose: 'Ok|4000',
			buttons: { Ok: { text: 'Ok'	} }
		});
	}

	successMessage = (message) => {
		$.confirm({
			title: 'Éxito',
			columnClass: 'col-md-6',
			type: 'green',
			content: message,
			autoClose: 'Ok|4000',
			buttons: {
				Ok: {
					text: 'Ok',
					action: () => { location.reload() }
				}
			 }
		});
	}

	async #deleteCall () {
		const headers = new Headers({ 'X-CSRF-Token': this.CSRF_TOKEN, 'Accept': 'application/json;charset=utf-8', });
		const request = new Request(this.BASE_URL, { method: 'DELETE', headers });

		fetch(request)
		.then(async response => {
			if (!response.ok) {
				const error = await response.text();
				throw new Error(error);
			}
			return response.json();
		})
		.then(data => {
			this.successMessage(data.success);
		})
		.catch((error) => {
			this.errorMessage('Error', `<span style="color: red;">${error.message}</span><br>`);
			console.error('Error:', error.message);
		});
	}
}

  export class IndexedDBService {
	constructor(tableName, dbVersion, indexOptions = {}) {
		this.dbName			= 'AgrosistemasDB';
		this.tableName		= tableName;
		this.dbVersion		= dbVersion;
		this.indexOptions	= indexOptions;
		this.db				= null;
	}

	async init() {
		return new Promise((resolve, reject) => {
			const request = window.indexedDB.open(this.dbName, this.dbVersion);

			request.onerror = (event) => {
				reject(`Error opening database: ${event.target.error}`);
			};

			request.onsuccess = (event) => {
				this.db = event.target.result;
				resolve();
			};

			request.onupgradeneeded = (event) => {
				const db = event.target.result;

				const objectStore = db.createObjectStore(this.tableName, { keyPath: 'id', autoIncrement: true });

				Object.keys(this.indexOptions).forEach((indexName) => {
					const index = this.indexOptions[indexName];
					objectStore.createIndex(indexName, index.keyPath, { unique: index.unique });
				});
			};
		});
	}

	async add(data) {
		return new Promise((resolve, reject) => {
			const transaction = this.db.transaction([this.tableName], 'readwrite');
			const objectStore = transaction.objectStore(this.tableName);
			const request = objectStore.add(data);

			request.onerror = (event) => {
				reject(`Error adding data: ${event.target.error}`);
			};

			request.onsuccess = (event) => {
				resolve(event.target.result);
			};
		});
	}

	async update(id, data) {
		return new Promise((resolve, reject) => {
			const transaction = this.db.transaction([this.tableName], 'readwrite');
			const objectStore = transaction.objectStore(this.tableName);
			const request = objectStore.put({ ...data, id });

			request.onerror = (event) => {
				reject(`Error updating data: ${event.target.error}`);
			};

			request.onsuccess = (event) => {
				resolve(event.target.result);
			};
		});
	}

	async delete(id) {
		return new Promise((resolve, reject) => {
			const transaction = this.db.transaction([this.tableName], 'readwrite');
			const objectStore = transaction.objectStore(this.tableName);
			const request = objectStore.delete(id);

			request.onerror = (event) => {
				reject(`Error deleting data: ${event.target.error}`);
			};

			request.onsuccess = (event) => {
				resolve(event.target.result);
			};
		});
	}

	async clear() {
		return new Promise((resolve, reject) => {
			const transaction = this.db.transaction([this.tableName], 'readwrite');
			const objectStore = transaction.objectStore(this.tableName);
			const request = objectStore.clear();

			request.onerror = (event) => {
				reject(`Error clearing table: ${event.target.error}`);
			};

			request.onsuccess = (event) => {
				resolve();
			};
		});
	}

	async getAll() {
		return new Promise((resolve, reject) => {
			const transaction = this.db.transaction([this.tableName], 'readonly');
			const objectStore = transaction.objectStore(this.tableName);
			const request = objectStore.getAll();

			request.onerror = (event) => {
				reject(`Error getting data: ${event.target.error}`);
			};

			request.onsuccess = (event) => {
				resolve(event.target.result);
			};
		});
	}

	async getById(id) {
		return new Promise((resolve, reject) => {
			const transaction = this.db.transaction([this.tableName], 'readonly');
			const objectStore = transaction.objectStore(this.tableName);
			const request = objectStore.get(id);

			request.onerror = (event) => {
				reject(`Error getting data: ${event.target.error}`);
			};

			request.onsuccess = (event) => {
				const result = event.target.result;
				if (result) {
					resolve(result);
				} else {
					reject(`Object with ID ${id} not found`);
				}
			};
		});
	}

	async searchByIndex(indexName, searchValue) {
		return new Promise((resolve, reject) => {
			const transaction = this.db.transaction([this.tableName], 'readonly');
			const objectStore = transaction.objectStore(this.tableName);
			const index = objectStore.index(indexName);
			const request = index.getAll(searchValue);

			request.onerror = (event) => {
				reject(`Error searching by ${indexName}: ${event.target.error}`);
			};

			request.onsuccess = (event) => {
				resolve(event.target.result);
			};
		});
	}
}

export class DateService {
	constructor(date = '') {
		const timezoneMeta = document.querySelector('meta[name="timezone"]');
		this.timezone = timezoneMeta ? timezoneMeta.getAttribute('content') : 'UTC';

		if (date) {
            this.date = new Date(date);
        } else {
            this.date = new Date();
        }
	}

	getDate() {
		return new Date(this.date.toLocaleString('en-US', { timeZone: this.timezone }));
	}

	getFormattedDateMXN() {
		return this.date.toLocaleDateString('es-MX', { timeZone: this.timezone });
	}

	getFormattedDate() {
		return this.getDate().toISOString().split('T')[0];
	}

	getFormattedTime() {
		return this.getDate().toLocaleTimeString('es-MX', { hour: 'numeric', minute: 'numeric', hour12: true });
	}

	getFormattedDateTime() {
		return `${this.getFormattedDate()} ${this.getFormattedTime()}`;
	}
}
